<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['username','operation','type']"
            ref="list"
			:is_created_get_list="false"
            :submit_preprocessing="submit_preprocessing"
            rowKey="id"
        >
			<template slot="right_btn">
				<!-- <export-table v-if="this.$store.state.page_auth.export" type="pre" :url="'/manage/crm.export/visitList' | export_form" ></export-table> -->
				<a v-if="this.$store.state.page_auth.export" @click="report()">
					<a-button class="a_btn" style='padding:0 15px;'>导出</a-button>
					
				</a>
            </template>

            <template slot="type" slot-scope="data">
                <a-tag color="#108ee9">
                    <span
                        v-for="item in type_list"
                        :key="item.key"
                        v-show="item.key == data.text"
                    >{{item.value}}</span>
                </a-tag>
            </template>
            <template slot="username" slot-scope="data">
                <span :style="data.record.user_status == -1 ? 'color:#FF2727;' : ''">{{data.record.username}}</span>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="get_details(data.record,data.index)">查看详情</a>
            </template>
        </TableList>
		<ImitationDetails ref="imitation_details" :visible.sync="drawer_visible"></ImitationDetails>
    </div>
</template>

<script>

import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import ImitationDetails from '@/views/imitation/index/details'

import { visitList, visitInfo } from "@/api/customer";

const columns = [
    {
        title: "人员",
        dataIndex: "username",
		scopedSlots: {
		    customRender: "username"
		}
    },
    {
        title: "部门",
        dataIndex: "department",
    },
    {
        title: "客户ID",
        dataIndex: "customer_id",
    },
    {
        title: "客户名称",
        dataIndex: "customer",
    },
	{
		title: "客户类型",
		dataIndex: "group",
		// 
	},
    {
        title: "客户地址",
        dataIndex: "address",
    },
    
    {
        title: "城乡类型",
        dataIndex: "town_type",
    },
    
    {
        title: "乡镇名称",
        dataIndex: "town",
    },
    {
        title: "备注",
        dataIndex: "remark",
    },
    {
        title: "标签",
        dataIndex: "type",
        scopedSlots: {
            customRender: "type"
        }
    },
    {
        title: "采集照片",
        dataIndex: "photo_num",
    },
    {
        title: "拜访时长",
        dataIndex: "duration",
    },
    {
        title: "拜访时间",
        dataIndex: "arrive_time",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

const columns_details = [
    {
        title: '商品',
        dataIndex: 'goods_title'
    },
    {
        title: '价格',
        dataIndex: 'pay_price'
    },
    {
        title: '数量',
        dataIndex: 'num',
        scopedSlots: {
            customRender: "num"
        }
    },
    {
        title: '小计',
        dataIndex: 'sub_price'
    }
]

let type_list = [
    {
        key: 0,
        value: '计划拜访'
    },
    {
        key: 1,
        value: '临时拜访'
    },
    {
        key: 2,
        value: '指定拜访'
    },
    {
        key: 3,
        value: '巡查'
    },
];


export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
		ImitationDetails
    },
    data() {
        return {
            get_table_list: visitList,
            type_list,
            submit_preprocessing: {
                array_to_string: ['department','group_id','role']
            },
            details: {
                visit_data: {

                },
            },
            columns,
            columns_details,
            drawer_visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
					},
                    {
                        type: "tree-select",
                        name: "role",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
					},
					// {
                    //     type: "select",
                    //     name: "role",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
                    // },
                    {
                        type: "text",
                        name: "username",
                        title: "人员",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
                    },
                    {
                        type: "text",
                        name: "customer",
                        title: "客户",
                        placeholder: '客户名称/ID/地址',
                        options: {

                        }
                    },
                    {
			            type: "tree-select",
			            name: "group_id",
			            title: "客户类型",
			            options: {},
			            treeData: [],
			            multiple: true
			        },
					// {
					//     type: "cascader",
					//     name: "group_id",
					//     title: "客户类型",
					//     list: [],
					//     placeholder: "请选择客户类型",
					//     mode: "default",
					//     options: {}
					// },
                    {
                        type: 'select',
                        name: 'type',
                        title: '标签',
                        list: type_list,
                        options: {},
					},
					{
                        type: "select",
                        name: "user_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                        list: this.$config.user_status_list
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "拜访时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
		});
		this.$method.get_group().then(res => {
			this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'group_id', 'treeData', res)
		});
		let query = this.$route.query;
		this.$nextTick(()=>{
			this.$refs.list.fixed_seo_data = {
				...query,
			}
			this.$refs.list.get_list();
		})
		
		// this.$method.get_role().then(res => {
        //     this.form_data_seo.list.forEach(item => {
        //         if (item.name == "role") {
        //             item.list = res;
        //         }
        //     });
        // });
        
        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "role") {
					item.treeData = res;
				}
			});
		});
    },
    methods: {
        get_details(item, index) {
			this.$refs.imitation_details.get_details(item);
        },
        show() {
            this.drawer_visible = true;
        },
        drawer_visible_close() {
            this.drawer_visible = false;
        },
		report() {
			this.$router.push({
				path:"/report/modify_report",
				query:{
					report_key:"custom_visit_list",
					...this.$refs.list.seo_data
				}
			});
		}
    }
};
</script>

<style lang="less" scoped>
@import url("../../assets/less/app.less");
.visit_img {
    width: 60px;
    height: 60px;
}
</style>